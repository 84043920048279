import React, { useState, useEffect, lazy, Suspense } from 'react';
import './App.css';
import Footer from './components/Layouts/Footer';
import Header from './components/Layouts/Header';
import Preloader from './components/utils/Preloader';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';

// Direct imports for Home, Dashboard, and AdminDashboard
import Home from './pages/Home/Home';
import Dashboard from './pages/dashboard/Dashboard';
import AdminDashboard from './pages/dashboard/AdminDashboard';
import GoToTop from './components/utils/GoToTop';
import Admins from './pages/Admins/Admins';
import AddAdmin from './pages/Admins/AddAdmin';

// Lazy load components
const Forgot = lazy(() => import('./pages/Auth/Forgot'));
const SignIn = lazy(() => import('./pages/Auth/SignIn'));
const Signup = lazy(() => import('./pages/Auth/Signup'));
const BuySell = lazy(() => import('./pages/BuySell/BuySell'));
const Institutions = lazy(() => import('./pages/Institutions/Institutions'));
const OTC = lazy(() => import('./pages/OTC/OTC'));
const InterestAccount = lazy(() => import('./pages/InterestAccount/InterestAccount'));
const Coins = lazy(() => import('./pages/Coins/Coins'));
const NotFound = lazy(() => import('./pages/NotFound/NotFound'));
const VerifyEmail = lazy(() => import('./utils/VerifyEmail'));
const ResetPassword = lazy(() => import('./pages/Auth/ResetPassword'));
const ContactUs = lazy(() => import('./pages/Contact/ContactUs'));
const Careers = lazy(() => import('./pages/Careers/Careers'));
const ResendVerification = lazy(() => import('./pages/Auth/ResendVerification'));
const AddCoin = lazy(() => import('./pages/Coins/AddCoin'));
const EditCoin = lazy(() => import('./pages/Coins/EditCoin'));
const TradePairs = lazy(() => import('./pages/Coins/TradePairs'));
const UserKYCList = lazy(() => import('./components/KYC/UserKYCList'));
const UserWalletBalances = lazy(() => import('./pages/wallets/UserWalletBalances'));
const UserWalletDeposits = lazy(() => import('./pages/wallets/UserWalletDeposits'));
const UserWalletWithdraws = lazy(() => import('./pages/wallets/UserWalletWithdraws'));
const TransactionsHistory = lazy(() => import('./pages/history/TransactionsHistory'));
const EditProfile = lazy(() => import('./pages/Profile/EditProfile'));
const TermsAndConditions = lazy(() => import('./pages/TermsAndConditions/TermsAndConditions'));
const ComplianceDisclosures = lazy(() => import('./pages/ComplianceDisclosures/ComplianceDisclosures'));
const Settings = lazy(() => import('./pages/Settings/Settings'));
const KYC = lazy(() => import('./pages/User/KYC'));
const Users = lazy(() => import('./pages/User/Users'));

function App() {
  const [loading, setLoading] = useState(true);
  const isLogged = useSelector((state) => state.user?.isLogged);
  const mode = useSelector((state) => state.user.mode);

  useEffect(() => {
    if (mode === 'dark') {
      document.body.style.backgroundColor = '#001a41';
    } else {
      document.body.style.backgroundColor = '#ffffff';
    }
  }, [mode]);

  useEffect(() => {
    const loadingTimer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(loadingTimer); // Clean up the timer
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        {loading && <Preloader />}
        <Header />
        <Suspense fallback={<Spinner animation="border" variant="dark" />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/ae" element={<Home />} />
            <Route path="/eu" element={<Home />} />
            <Route path="/buy-and-sell" element={<BuySell />} />
            <Route path="/institutions" element={<Institutions />} />
            <Route path="/otc" element={<OTC />} />
            <Route path="/interest-accounts" element={<InterestAccount />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/forgot" element={<Forgot />} />
            <Route path="/resend-verify" element={<ResendVerification />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/terms-of-service" element={<TermsAndConditions />} />
            <Route path="/disclosure-statement" element={<ComplianceDisclosures />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="*" element={<NotFound />} />
            {isLogged && (
              <>
                <Route path="/client/dashboard" element={<Dashboard />} />
                <Route path="/client/kyc" element={<KYC />} />
                <Route path="/client/wallets/balances" element={<UserWalletBalances />} />
                <Route path="/client/wallets/deposits" element={<UserWalletDeposits />} />
                <Route path="/client/wallets/withdraws" element={<UserWalletWithdraws />} />
                <Route path="/client/history/transactions" element={<TransactionsHistory />} />
                <Route path="/client/profile-update" element={<EditProfile />} />
                <Route path="/client/settings" element={<Settings />} />
                <Route path="/admin/dashboard" element={<AdminDashboard />} />
                <Route path="/admin/coins" element={<Coins />} />
                <Route path="/admin/add-coin" element={<AddCoin />} />
                <Route path="/admin/coins/edit/:id" element={<EditCoin />} />
                <Route path="/admin/coins/trade-pairs" element={<TradePairs />} />
                <Route path="/admin/users" element={<Users />} />
                <Route path="/admin/admins" element={<Admins />} />
                <Route path="/admin/add-admin" element={<AddAdmin />} />
                <Route path="/admin/users-kyc" element={<UserKYCList />} />
                <Route path="/admin/settings" element={<Settings />} />
              </>
            )}
          </Routes>
        </Suspense>
        <Footer />
        <GoToTop />
      </div>
    </BrowserRouter>
  );
}

export default App;
