import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function AddAdmin() {
    const navigate = useNavigate(); // Hook for navigation
    const [adminData, setAdminData] = useState({
        FirstName: '',
        LastName: '',
        email: '',
        password: '',
        role: 'admin',
        isVerified: true
    });

    const [statusMessage, setStatusMessage] = useState('');
    const [statusType, setStatusType] = useState(''); // success or error
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setAdminData((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate form input
        if (!adminData.FirstName || !adminData.LastName || !adminData.email || !adminData.password) {
            showAlert('Please fill in all fields.', 'error');
            return;
        }

        setLoading(true);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/users`, adminData, {
                withCredentials: true,
            });

            if (response.status === 200) {
                showAlert('Admin added successfully.', 'success');
                setAdminData({
                    FirstName: '',
                    LastName: '',
                    email: '',
                    password: '',
                    role: 'admin',
                    isVerified: true
                });

                // Redirect after 5 seconds
                setTimeout(() => {
                    navigate('/admin/admins');
                }, 5000);
            }
        } catch (error) {
            if (error.response) {
                const { status, data } = error.response;

                if (status === 400) {
                    showAlert(data.message || 'Invalid request. Please check the input data.', 'error');
                } else if (status === 401) {
                    showAlert(data.message || 'Email already exists. Please use a different email.', 'error');
                } else {
                    showAlert(data.message || 'An unexpected error occurred. Please try again.', 'error');
                }
            } else {
                showAlert('Unable to connect to the server. Please try again later.', 'error');
            }
        } finally {
            setLoading(false);
        }
    };

    const showAlert = (message, type) => {
        setStatusMessage(message);
        setStatusType(type);

        // Automatically hide alert after 5 seconds
        setTimeout(() => {
            setStatusMessage('');
            setStatusType('');
        }, 5000);
    };

    return (
        <div className="page">
            <div className="container">
                <div className="row mt-4">
                    <div className="col">
                        <div className="card">
                            <div className="card-header">
                                <h2 className="card-title mb-0">Add New Admin</h2>
                            </div>
                            <div className="card-body">
                                <div className="row justify-content-center">
                                    <div className="col-6">
                                        {statusMessage && (
                                            <div
                                                className={`alert ${statusType === 'success'
                                                    ? 'alert-success'
                                                    : 'alert-danger'
                                                    }`}
                                                role="alert"
                                            >
                                                {statusMessage}
                                            </div>
                                        )}
                                        <form onSubmit={handleSubmit}>
                                            <div className="mb-3">
                                                <div className="row">
                                                    <div className="col">
                                                        <label htmlFor="FirstName" className="form-label">
                                                            Admin First Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="FirstName"
                                                            value={adminData.FirstName}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <label htmlFor="LastName" className="form-label">
                                                            Admin Last Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="LastName"
                                                            value={adminData.LastName}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="email" className="form-label">
                                                    Admin Email
                                                </label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    id="email"
                                                    value={adminData.email}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="password" className="form-label">
                                                    Password
                                                </label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    id="password"
                                                    value={adminData.password}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <button
                                                type="submit"
                                                className="btn btn-success"
                                                disabled={loading}
                                            >
                                                {loading ? 'Adding...' : 'Add Admin'}
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}