import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import Swal from "sweetalert2";
import { FiUserCheck, FiUserX, FiTrash } from "react-icons/fi"

export default function AdminsTable() {
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const getUsers = async () => {
        setIsLoading(true)
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/byRole/admin`, { withCredentials: true })
        if (response) {
            setUsers(response?.data.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1))
            setIsLoading(false)
        }
    }
    useEffect(() => {
        getUsers()
        // eslint-disable-next-line
    }, []);

    const columns = [
        {
            name: "No.",
            cell: (row, index, column, id) => <div>{index + 1}</div>,
            selector: row => (console.log(row)),
            width: "60px"
        },
        {
            name: 'Name',
            cell: (row) => <div className='d-flex align-items-center text-wrap'><img src={row.avatar} width={20} height={20} className="my-2 rounded-circle me-2" alt={`${row.FirstName}`} />  {row.FirstName} {row.LastName}</div>,
            selector: row => (row),
            grow: 2
        },
        {
            name: 'Email',
            cell: row => <div className='text-wrap'>{row.email}</div>,
            selector: row => (row.email),
            grow: 2

        },
        {
            name: 'Status',
            cell: row => <div>{row.isActive ? <span className="badge text-bg-success">Active</span> : <span className="badge text-bg-danger">Deactivated</span>}</div>,
            selector: row => (row.isActive),
            center: true
        },
        {
            name: 'Action',
            cell: row => <div>
                {(() => {
                    switch (row.isActive) {
                        case true:
                            return <button className='btn btn-warning btn-sm me-1' onClick={() => activeDeactiveUser(row._id, row.isActive)}><FiUserX title="Inactive Account" /></button>;
                        case false:
                            return <button className='btn btn-success btn-sm' onClick={() => activeDeactiveUser(row._id, row.isActive)}><FiUserCheck title="Active Account" /></button>;
                        default:
                            return
                    }
                })
                    ()}
                <button className='btn btn-danger btn-sm' onClick={() => deleteUser(row._id)}><FiTrash title="Delete User" /></button>
            </div>,
            center: 'yes'
        },
    ];
    const activeDeactiveUser = async (userId, isActive) => {
        const data = {
            isActive: !isActive
        }
        Swal.fire({
            title: "Are you sure?",
            text: "You want to change user status?",
            //icon: "warning",
            dangerMode: true,
            showCancelButton: true,
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.put(`${process.env.REACT_APP_API_URL}/users/` + userId, data, { withCredentials: true })
                    .then(res => {
                        getUsers()
                        Swal.fire({
                            title: "Done!",
                            text: "User Status Successfully Changed",
                            icon: "success",
                            timer: 2000,
                            button: false
                        })

                    });
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {

            }
        })
    }
    const deleteUser = async (userId) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this user?",
            //icon: "warning",
            dangerMode: true,
            showCancelButton: true,
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${process.env.REACT_APP_API_URL}/users/` + userId, { withCredentials: true })
                    .then(res => {
                        getUsers()
                        Swal.fire({
                            title: "Done!",
                            text: "User Deleted Successfully",
                            icon: "success",
                            timer: 2000,
                            button: false
                        })

                    });
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {

            }
        })
    }
    return (
        <div>
            <div className='d-flex justify-content-center'>
                {isLoading && <Spinner animation="border" variant="dark" />}
            </div>
            <DataTable
                columns={columns}
                data={users}
                pagination
                striped
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 50]}
            />
        </div>
    )
}
