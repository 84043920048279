import React from 'react';
import { FaFacebookF, FaLinkedinIn, FaInstagram, FaYoutube, FaTwitter, FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import TradeBuySell from '../Trades/TradeBuySell';
import { useSelector } from 'react-redux';

const SocialLinks = ({ mode }) => (
    <ul className="social">
        {[FaFacebookF, FaLinkedinIn, FaInstagram, FaYoutube, FaTwitter].map((Icon, index) => (
            <li className="social__item" key={index}>
                { //eslint-disable-next-line
                    <a href="/" className={`social__link social__link--style1 ${mode === 'dark' ? 'bg-light' : 'bg-dark'}`}>
                        <Icon />
                    </a>
                }
            </li>
        ))}
    </ul>
);

export default function Banner() {
    const bgImage = '/assets/images/home/bg.png';
    const bgDarkImage = '/assets/images/home/bg-dark.png';
    const mode = useSelector((state) => state.user.mode);

    return (
        <section className="banner banner--style1"
            style={{
                backgroundImage: `url(${mode === 'dark' ? bgDarkImage : bgImage})`,
            }}
        >
            <div className="container">
                <div className="banner__wrapper">
                    <div className="row py-5 d-flex align-items-center">
                        <div className="col-lg-6 col-md-7">
                            {mode === 'dark' ?
                                <div className="banner__content aos-animate" data-aos="fade-right" data-aos-duration="800">
                                    {/* <div className="banner__content-coin">
                                        <img src="/assets/images/home/3.png" alt="coin icon" />
                                    </div> */}

                                    <h1 className="banner__content-heading text-light">
                                        Premium Cryptocurrency <span>Exchange</span> for Institutions and Traders
                                    </h1>
                                    <p className="banner__content-moto text-light">
                                        Tailored for institutions
                                        <br />Preferred by everyday traders
                                    </p>
                                    <div className="banner__btn-group btn-group">
                                        <Link to="/signup" className="trk-btn trk-btn--primary trk-btn--arrow">Get Started <FaArrowRight /></Link>
                                        <Link to="/contact-us" className="trk-btn trk-btn--outline22 text-light"> Contact us</Link>
                                    </div>
                                    {/* <div className="banner__content-social">
                                        <p className='text-light'>Follow Us</p>
                                        <SocialLinks mode={mode} />
                                    </div> */}
                                </div>
                                :
                                <div className="banner__content aos-animate" data-aos="fade-right" data-aos-duration="800">
                                    {/* <div className="banner__content-coin">
                                        <img src="/assets/images/home/3.png" alt="coin icon" />
                                    </div> */}

                                    <h1 className="banner__content-heading">
                                        Premium Cryptocurrency <span>Exchange</span> for Institutions and Traders
                                    </h1>
                                    <p className="banner__content-moto">
                                        Tailored for institutions
                                        <br />Preferred by everyday traders
                                    </p>
                                    <div className="banner__btn-group btn-group">
                                        <Link to="/signup" className="trk-btn trk-btn--primary trk-btn--arrow">Get Started <FaArrowRight /></Link>
                                        <Link to="/contact-us" className="trk-btn trk-btn--outline22"> Contact us</Link>
                                    </div>
                                    {/* <div className="banner__content-social">
                                        <p>Follow Us</p>
                                        <SocialLinks mode={mode} />
                                    </div> */}
                                </div>
                            }

                        </div>
                        <div className="col-sm-12 col-md-5 col-lg-6">
                            <div className="d-flex justify-content-center justify-content-lg-en aos-animate" data-aos="fade-left" data-aos-duration="800">
                                <div className="trade-options">
                                    <div className="card rounded-xl w-100">
                                        <div className="card-body">
                                            <TradeBuySell />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="banner__shape">
                {/* <span className="banner__shape-item banner__shape-item--1">
                    <img src="/assets/images/home/4.png" alt="shape icon" /></span> */}
            </div>
        </section>
    );
}
