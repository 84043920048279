import React from 'react'
import AdminsTable from '../../components/Trades/AdminsTable'
import { Link } from 'react-router-dom'

export default function Admins() {
    return (
        <div className='page'>
            <div className="container">
                <div className="row mt-4">
                    <div className="col">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col"><h2 className="card-title">Admins</h2></div>
                                    <div className="col text-end">
                                        <Link to="/admin/add-admin" className='btn btn-success text-white'>Add Admin</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <AdminsTable />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
